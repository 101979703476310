.stepper-step-icon {
    z-index: 1;
    width: 1.625rem;
    height: 1.625rem;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    fill: none;
    overflow: visible;
}

.stepper-step-icon-text {
    fill: black;
    font-size: 0.75rem;
    font-weight: bold;
}

.stepper-step-icon.error .stepper-step-icon-text {
    fill: white;
}

.stepper-step-icon.disabled .stepper-step-icon-text {
    fill: #6c757d;
}

.stepper-step-icon.completed.disabled .stepper-step-icon-text,
.stepper-step-icon.active.disabled .stepper-step-icon-text,
.stepper-step-icon.error.disabled .stepper-step-icon-text {
    fill: white;
}

.stepper-step-icon-circle {
    fill: currentcolor;
    color: white;
    stroke: #1b9e9e;
    stroke-width: 1px;
}

.stepper-step-icon.disabled .stepper-step-icon-circle {
    stroke: #6c757d;
    color: white;
}

.stepper-step-icon.completed .stepper-step-icon-circle {
    stroke: none;
    color: #92b2b2;
}

.stepper-step-icon.active .stepper-step-icon-circle {
    stroke: none;
    color: #1b9e9e;
}

.stepper-step-icon.error .stepper-step-icon-circle {
    stroke: none;
    color: #ac2222;
}

.stepper-step-icon.completed.disabled .stepper-step-icon-circle,
.stepper-step-icon.active.disabled .stepper-step-icon-circle,
.stepper-step-icon.error.disabled .stepper-step-icon-circle {
    stroke: none;
    color: #6c757d;
}