@use "sass:math";
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
$navbarHeight: 4.375rem;
$navDropdownHeight: math.div(calc($navbarHeight - $h1-font-size - .6rem), 2);
$navLogoWidth: 200px;
$navUserIconHeight: 50px;

.topnav {
  background-image: url("../../images/topBar.png");
  border-bottom: solid 2px rgb(13, 43, 83);
  background-size: cover;
  height: $navbarHeight;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  div.nav-item.dropdown {
    max-width: calc(100% - $navLogoWidth - $navUserIconHeight - 25px);
  }

  a {
    float: left;
    text-align: center;
    padding: 9px 14px;
    text-decoration: none;
    font-size: 17px;
    padding-top: $navDropdownHeight;
    padding-bottom: $navDropdownHeight;

    &.dropdown-toggle {
      color: #f2f2f2;
      padding-right: 20px;
      max-width: 100%;

      &>h1 {
        max-width: 100%;
        margin-bottom: 0;
      }

      &>.dropdown {
        max-width: calc(100% - $navLogoWidth - $navUserIconHeight - 25px);
      }
    }

    &:focus {
      color: white;
    }

    &:hover {
      background-color: #ddd;
      color: black;
    }

    &.active {
      background-color: #04AA6D;
      color: white;
    }
  }
}

.topnavTitle {
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 100px;
  display: inline-block;
  width: 100%;
}

.topnavFlex {
  justify-content: space-between;
}

.nav-item .dropdown-toggle::after {
  display: inline;
}

.userIcon {
  cursor: pointer;
  height: $navUserIconHeight;
}

.userNavDropdown {
  color: black;
}