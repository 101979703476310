/*
    Combined Sections
*/

div.banner,
div.logo-container {
  margin-bottom: 2.5rem;
}

/*
  Banner
*/
div.banner {
  text-align: center;
  padding: 25px 0 10px 0;
}

div.banner a {
  color: black;
  text-decoration: none;
}

div.banner p {
  margin-bottom: 0.5rem;
}

/*
  Logo Container
*/
div.logo-container div {
  align-items: center;
  margin-left: auto;
}

button.login,
button.login:hover,
button.login:focus {
  font-weight: bold;
  background-color: #2561b3;
  color: #ffffff;
  border-color: #2561b3;
}

button.login:active {
  background-color: #0e50ad !important;
  color: #ffffff !important;
  border-color: #0e50ad !important;
}

/*
  Filters
*/
div.filters-container {
  margin-bottom: 1rem;
  font-size: 12px;
}

div.filters-container * {
  font-size: 0.8rem !important;
}

button.search,
button.search:hover,
button.search:focus {
  font-weight: bold;
  background-color: #b5d4ff;
  color: #7e7d7d;
  border-color: #b5d4ff;
}

button.search:active {
  background-color: #9cc3fa !important;
  color: #7e7d7d !important;
  border-color: #9cc3fa !important;
}

button.reset,
button.reset:hover,
button.reset:focus {
  font-weight: bold;
  background-color: #6e92c4;
  color: #ffffff;
  border-color: #6e92c4;
}

button.reset:active {
  background-color: #5682c0 !important;
  color: #ffffff !important;
  border-color: #5682c0 !important;
}

/*
  Hospital Card
*/
div.card-body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

div.card-body div.title,
div.card-body label.title {
  color: #7e7d7d;
  font-size: 14px;
}

div.card-body div.title,
div.card-body label.title,
div.card-body div.value {
  font-weight: bold;
}

div.card-footer {
  padding: 0;
  background-color: white;
  border-top: 0;
  font-size: 14px;
}

div.card-footer div.title {
  margin-right: 10px;
  font-weight: bold;
}

button.toggle-button {
  padding: 1px 6px;
}

/*
  Pagination
*/
ul.pagination {
  justify-content: center;
}

/*
  Login
*/
button.login a {
  color: white;
  text-decoration: none;
}


/* For small screen */

@media (max-width: 767.98px) {
  .row-group>.col-group {
    &:nth-of-type(even) {
      background-color: #e3eeff !important;
    }

    &:nth-of-type(odd) {
      background-color: white !important;
    }
  }
}

@media (min-width: 992px) {
  .row-group>.col-group {

    &:nth-of-type(4n+3),
    &:nth-last-of-type(4n+4) {
      background-color: #e3eeff !important;
    }

    &:nth-of-type(4n+1),
    &:nth-of-type(4n+2) {
      background-color: white !important;
    }
  }
}