@import "../../sass/variables";

.mainContent-callTriage .nav-pills {
  --bs-nav-pills-border-radius: 0;
}

.mainContent-callTriage .nav-pills .nav-link {
  color: $it-dark-blue;
  background-color: $it-eggshell;
  --bs-border-opacity: 1;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-color: rgba(var(--bs-it-dark-blue-rgb), var(--bs-border-opacity));
}

.mainContent-callTriage .nav-pills .nav-link.active,
.mainContent-callTriage .nav-pills .show > .nav-link {
  color: $it-eggshell;
  background-color: $it-dark-blue;
}

.mainContent-callTriage .pagination {
  --bs-pagination-active-bg: rgba(var(--bs-it-dark-blue-rgb));
  --bs-pagination-active-border-color: rgba(var(--bs-it-dark-blue-rgb));
  --bs-link-color: rgba(var(--bs-it-dark-blue-rgb));
}