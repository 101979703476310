.stepper-step-label {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.stepper-step-label.disabled {
    cursor: default;
}

.stepper-step-label-text {
    color: black;
    display: block;
    margin-top: 16px;
    text-align: center;
}

.stepper-step-label.disabled .stepper-step-label-text {
    color: #6c757d;
}

.stepper-step-label.active .stepper-step-label-text,
.stepper-step-label.completed .stepper-step-label-text {
    font-weight: bold;
}

.stepper-step-label.error .stepper-step-label-text {
    color: #ac2222;
}

.stepper-step-label-iconContainer {
    display: flex;
    flex-shrink: 0;
    padding-right: 0;
}

.stepper-step-label-textContainer {
    width: 100%;
}