.stepper-step-button {
    width: 100%;
    margin: -24px -16px;
    padding: 24px 16px;
    box-sizing: content-box;
    border: none;
    background-color: transparent;
}

.stepper-step-button:active,
.stepper-step-button:focus {
    outline: none;
}

.stepper-step-button:focus {
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgb(43 38 108 / 50%);
}