$small-map-height: 45vh;
$large-map-height: 70vh;

.map {
  height: $small-map-height;
  width: 100%;
  border: rgb(0, 106, 177) solid 1px;
}

@media (min-width: 992px) {
  .map {
    height: $large-map-height;
  }

  .row>.signal-incident--col {
    /* Move the column up by the map height */
    margin-top: -$large-map-height;
  }
}