.list-table>thead>tr>th {
    border-top: none;
    border-bottom: 1px solid black;
    padding-bottom: 0.5rem;
}

.list-table>thead>tr>th,
.list-table>thead>tr>th .btn {
    font-weight: normal;
    font-size: 0.75rem;
}

.list-table>thead>tr>th a,
.list-table>thead>tr>th .btn-link {
    color: var(--color-page-background-contrast);
    padding: 0;
}

.list-table>tfoot>tr>td {
    border-top-color: black;
}

.list-table .btn.record-link {
    text-align: left;
}

.list-table .list-table-col-integer,
.list-table .list-table-col-integer .btn.record-link,
.list-table .list-table-header-col-integer,
.list-table .list-table-header-col-integer .btn.record-link,
.list-table .list-table-col-number,
.list-table .list-table-col-number .btn.record-link,
.list-table .list-table-header-col-number,
.list-table .list-table-header-col-number .btn.record-link {
    text-align: right;
}

/* Small columns should not take up more space than necessary.
 https://stackoverflow.com/questions/2700338/table-column-width-as-narrow-as-contained-data */
.list-table .list-table-col-shrink {
    white-space: nowrap;
    width: 1px;
}

/* Use flex to make sure the sort indicator is not wrapped to its own line, while still allowing the table header to wrap. */
.list-table .list-table-header-col .btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.list-table .list-table-header-col .btn .label {
    flex: 1 1 auto;
}

.list-table .list-table-header-col .btn .sort-indicator {
    margin-left: 3px;
}

.list-table .list-table-row-disabled {
    background-color: lightgray !important;
    font-size: small;
}

.list-table .list-table-row .list-table-col {
    padding: 0.75rem;
}

.list-table .list-table-row .list-table-col .record-link {
    padding: 0.75rem;
}

.list-table .record-link {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: black;
}

.list-table .record-link:empty:hover,
.list-table .record-link.empty:hover {
    text-decoration: none;
}

.list-table .record-link:empty::after {
    /* If the link is empty, then it doesn't get any height and can't be clicked.  This workaround adds a &nbsp; */
    content: "\00a0";
}

.list-table.table-striped tbody tr:nth-of-type(odd) {
    background-color: inherit;
    color: inherit;
}

.list-table.table-striped tbody tr:nth-of-type(even) {
    background-color: var(--color-role-basic);
    color: var(--color-role-basic-contrast);
}

.list-table {
    background-color: white;
    padding: 0.75rem;
}