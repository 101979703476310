@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$it-eggshell: rgb(245,245,245);
$it-dark-blue: rgb(11,49,99);

// change the theme
$custom-colors: (
  "it-red": rgb(255, 0, 0),
  "it-blue": rgb(17, 0, 255),
  "it-green": rgb(70, 167, 7),
  "it-purple": rgb(79, 38, 131),
  'it-gold': rgb(255, 198, 47),
  'it-brown': rgb(150, 75, 0),
  'it-black': rgb(0, 0, 0),
  'it-ems': rgb(0, 106, 177),
  'it-white': rgb(255, 255, 255),
  'it-light-gray': rgb(191, 191, 191),
  'it-gray': rgb(139, 139, 139),
  'it-dark-blue': $it-dark-blue,
  'it-eggshell': $it-eggshell,
  'it-dark-gray': rgb(66,66,66),
  'it-light-blue': rgb(195,212,236),
  'it-golder': rgb(255,213,0),
  'orange': $orange,
);

$theme-colors: map-merge($theme-colors, $custom-colors);