@import "_variables.scss";
@import "./components/navbar/navbar.scss";

* {
  font-family: 'Ubuntu', sans-serif;
}

html,
body,
#root {
  min-height: 100vh;
  height: 100vh;
  background: rgb(199, 199, 199);
}

.removal {
  margin-left: 15px;
}

.addResponderModalButton {
  padding: 10px;
  height: 80px;
  width: 120px;
}

#dropText {
  color: black;
}

#hide-switch {
  margin-top: 10px;
  padding-left: 70px;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.blurry-text::selection {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.form-title {
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
}

.small {
  color: white;
  margin-right: -15px;
}

.fa-map {
  color: white;
  height: 50px;
}

.responderSwitch {
  margin-left: 0px;
  margin-top: 10px;
}

.center {
  margin: 0 auto;
}

.dashboardScreen {
  width: 100%;
}

.signupModal>.modal-dialog {
  max-width: 50vw;
  min-width: 50vw !important;
  width: 50vw;

}

.psap-table {
  max-height: 30vh;
  overflow: auto;
}

.psap-table .selected {
  background-color: #cef6f5 !important;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

#infoTitle {
  margin-bottom: -5px;
}

#infoText {
  margin-bottom: -8px;
}

.cardIcon {
  margin-left: 10px;
}

.form-bg {
  background-color: rgba(13, 25, 50, 0.7);
}

.form-border {
  border: solid 2px rgba(0, 106, 177, 0.7);
}

#login-logo {
  width: $navLogoWidth;
  filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(76deg) brightness(200%) contrast(100%);
}

.org-name {
  font-size: 2vw;
  text-align: center;
}

.logoContainer {
  width: 270px;
}

.card-title {
  font-weight: bold;
  font-size: 20px;
}

.card-text {
  margin-top: -3px;
  font-size: 15px;
}

.card-location {
  margin-top: -15px;
  font-size: 15px;
}

.card-status {
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
}

.textRight {
  text-align: right;
  margin-right: 15px;
}

.textLeft {
  text-align: left;
}

.card-subtext {
  margin-top: -15px;
  font-size: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cardBg {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left-width: .5rem;
  border-left-style: solid;
}

.incidentCard {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(0, 106, 177);
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(0, 106, 177) .5rem solid;
  margin-bottom: 20px;
  padding-bottom: 30px;
  /* margin-left: 50px;
  margin-right: -50px; */
}

.cardBgInactive {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
}

.responderRow {
  border-top: 1px grey solid;
  margin-top: 10px;
}

.responderHover :hover {
  background: rgb(226, 226, 226);
}

.editResponder {
  z-index: 2;
  position: fixed;
  left: 40%;
  top: 30%;
}

.incidentCardTitle p {
  font-weight: bold;
  font-size: 18px;
}

.incidentCardText {
  font-size: 15px;
}

.locationCard {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgb(0, 106, 177);
  background-color: rgba(253, 252, 252);
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.locationCardTitle p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: -5px;
}

.locationCardText {
  font-size: 15px;
  margin-bottom: -5px;
}

.respondersCard {
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  border-color: rgb(0, 106, 177);
  background-color: rgba(253, 252, 252);
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.aResourceCard {
  border-style: solid;
  border-color: rgb(192, 1, 1);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(192, 1, 1) 2rem solid;
}

.unavailableCard {
  border-style: solid;
  border-color: rgb(192, 1, 1);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(192, 1, 1) 8px solid;
  margin-bottom: -20px;
}

.arrivedCard {
  border-style: solid;
  border-color: rgb(126, 0, 126);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(126, 0, 126) 8px solid;
  margin-bottom: -20px;
}

.respondingCard {
  border-style: solid;
  border-color: rgb(28, 156, 2);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(28, 156, 2) 8px solid;
  margin-bottom: -20px;
}

.closedCard {
  border-style: solid;
  border-color: rgb(17, 48, 187);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(17, 48, 187) 8px solid;
  margin-bottom: -20px;
}

.text-large {
  font-size: 112.5%;
}

.text-small {
  font-size: 87.5%;
}

.badge.badge-large {
  font-size: 1em;
}

.responderCard {
  border-style: solid;
  border-color: rgb(0, 106, 177);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(0, 106, 177) 1rem solid;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
}

.responderCardTitle {
  font-weight: bold;
  margin-left: 20px;
  font-size: 18px;
}

.responderCardText {
  font-size: 17px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.responderCardDigit {
  font-weight: bold;
  color: white;
  background-color: rgb(0, 106, 177);
  border-radius: 5px;
  padding: 4px;
}

.responderDigit {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}

.responderSubText {
  text-align: center;
}

.iResourceCard {
  border-style: solid;
  border-color: rgb(39, 175, 12);
  border-width: 1px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  border-left: rgb(39, 175, 12) 2rem solid;
}

.responseCard {
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  background-color: rgba(253, 252, 252);
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: -50px;
}

.resourceIcon {
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.timelineCard {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(0, 106, 177);
  background-color: rgba(253, 252, 252);
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 10px;
  padding-bottom: 30px;
}

.timelineSubText {
  font-size: 13px;
  margin-bottom: -10px;
  text-align: left;
}

.timelineTimeText {
  font-size: 13px;
  margin-bottom: -10px;
  text-align: right;
}

.timelineCardTitle {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 15px;
}

.timelineCardText {
  font-size: 15px;
}

.incidentBG {
  background: rgb(199, 199, 199);
  overflow-x: hidden;
  height: auto;
}

.back {
  height: 40px;
  width: 40px;
  filter: invert(9%) sepia(25%) saturate(5601%) hue-rotate(227deg) brightness(91%) contrast(99%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.detailsHeader {
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-right: 10px;
}

.bg {
  background-image: url("images/squaresBG.png");
  background-attachment: fixed;
  overflow: hidden;
  background-repeat: repeat-x;
  margin-top: $navbarHeight;
  opacity: 0.612 linear-gradient(180deg, rgba(162, 162, 162, 1) 0%, rgba(246, 246, 246, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.forgotHeader {
  text-align: center;
  color: white;

}

.forgotContent {
  padding-left: 40px;
  padding-right: 40px;
}

#forgotPanelTop {
  margin-top: 30px;
}

/** The .leaflet* classes control map display */
.leaflet-bottom {
  margin-top: auto;
}

.leaflet-right {
  margin-left: auto;
}

.leaflet-control-container {
  display: flex;
  height: 100%;
  margin-top: auto;
  margin-left: auto;
}

.card-icon {
  height: 45px;
  width: 45px;
  padding-top: 10px;
  align-self: center;
}

.empty {
  font-size: 24px;
  margin-top: 20px;
}

.topRow {
  position: fixed;
  background: rgb(199, 199, 199);
  top: 76px;
  padding-top: 10px;
  width: 90%;
  padding-right: 30px;
  padding-bottom: 15px;
  z-index: 1;

}

#updateTime {
  text-align: right;
  font-weight: bold;
  margin-top: 15px;
  margin-right: 20px;
}

.pageHeader {
  font-weight: bold;
  color: $pageHeaderColor;
}

#logo {
  background-color: rgb(255, 255, 255);
  width: 250px;
  height: 70px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: -10px;
}

.leaflet-popup-close-button {
  visibility: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dashboardContent {
  margin-top: 80px;
}

.right {
  text-align: right;
}

.contentWrapper {
  display: flex;
  /** Account for the top navbar height **/
  height: calc(100vh - $navbarHeight);
}

.contentWrapper-login,
.contentWrapper-create1,
.contentWrapper-create2,
.contentWrapper-create3 {
  margin: 0;
  height: 100vh !important;
}

.mainContent {
  background: rgb(199, 199, 199);
  width: 100%;
  padding: 0px 10px 10px;
  margin-top: 10px;
  overflow-y: auto;
}

.mainContent-gray {
  background: rgb(199, 199, 199);
}

.mainContent.no-nav {
  padding: 0;
  margin-top: 0;
  background-image: url("images/BG_full.jpg");
  background-size: cover;
  height: 100vh;
}

.incident-map {
  height: 30vh;
}

div.modal,
div.modal-backdrop {
  z-index: 9999;
}

.clickable {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (orientation: portrait) {
  #map {
    height: 400px;
    width: 85%;
    border: rgb(0, 106, 177) solid 1px;
    /* position: fixed; */
    left: 180px;
    top: 100px;
  }
}

@media print {
  .mainContent {
    overflow-y: unset;
  }

  .collapse:not(.show) {
    display: unset !important;
  }

  .fade {
    opacity: 1 !important;
  }

  .tab.nav-pills {
    display: none;
  }

  .topnav div.nav-item.dropdown {
    width: calc(100% - $navLogoWidth - 25px);
  }
}