.white-bg {
  background: white;
  padding: 0.75rem;
}

.wrapper {
  display: flex;
  flex-flow: row wrap;
  font-weight: bold;
  text-align: left;
}

.wrapper>* {
  padding: 10px;
  flex: 1 100%;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-muted {
  font-weight: lighter;
}

.shallowbottom-hr {
  margin-bottom: -1rem;
}

.clickable {
  cursor: pointer;
}