.stepper-step-connector {
    flex: 1 1 auto;
    top: 12px;
    left: calc(-50%);
    right: calc(50%);
    position: absolute;
    z-index: 0;
}

.stepper-step-connector.first {
    left: 0;
}

.stepper-step-connector-line {
    display: block;
    border-color: #bdbdbd;
    border-top-style: solid;
    border-top-width: 2px;
    border-radius: 5px;
}

.stepper-step-connector.active,
.stepper-step-connector.completed {
    top: 10px;
}

.stepper-step-connector.active .stepper-step-connector-line,
.stepper-step-connector.completed .stepper-step-connector-line {
    border-color: #1b9e9e;
    border-top-width: 7px;
}