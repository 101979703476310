label.form-label {
  margin-bottom: 0;
}

.map-card>.card-body>.nav .nav-item:first-child .nav-link {
  border-top-left-radius: var(--bs-border-radius);
}

.map-card>.card-body>.nav .nav-item:last-child .nav-link {
  border-top-right-radius: var(--bs-border-radius);
}