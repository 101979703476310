$sideNavWidth: 90px;

.sidenav-wrapper {
  display: flex;
  flex: 0 0 $sideNavWidth;
}

/* The side navigation menu */
.sidenav {
  background-image: url("../../images/sideBar.png");
  background-color: rgb(13, 25, 50);
  background-size: cover;
  border-right: solid 2px rgb(13, 43, 83);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  a,
  p {
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s;
  }

  a {
    &:hover {
      color: #f1f1f1;
    }

    &.active {
      background-color: #3198BB;
    }
  }

  img {
    display: block;
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }

  p {
    font-size: 10pt;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}